import React from 'react'

import jiraIcon from '../images/jira-icon.svg'
import linearIcon from '../images/linear-icon.svg'
import trelloIcon from '../images/trello-icon.svg'
import dovetailIcon from '../images/dovetail-icon.svg'
import googleGIcon from '../images/google-g-icon.svg'
import githubIcon from '../images/github.svg'
import asanaIcon from '../images/asana-icon.svg'
import figmaIcon from '../images/figma-icon.svg'

export const IntegrationIcon = ({ id, alt, ...props }) => {
  let icon
  switch (id) {
    case 'jira':
      icon = jiraIcon
      break
    case 'linear':
      icon = linearIcon
      break
    case 'trello':
      icon = trelloIcon
      break
    case 'dovetail':
      icon = dovetailIcon
      break
    case 'google-sheets':
      icon = googleGIcon
      break
    case 'github':
      icon = githubIcon
      break
    case 'asana':
      icon = asanaIcon
      break
    case 'figma':
      icon = figmaIcon
      break
    default:
      icon = null
      break
  }

  return <img src={icon} alt={alt} {...props} />
}
