import React from 'react'
import { Link } from 'gatsby'

import { ArrowRight } from 'phosphor-react'

import { useIntegrations } from '../hooks/useIntegrations'
import { IntegrationIcon } from './IntegrationIcon'

export const Integrations = () => {
  const integrations = useIntegrations()

  return (
    <section className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-8 md:gap-x-12 md:gap-y-16 px-8 lg:px-0">
      {integrations.map((integration) => (
        <Integration
          key={integration.id}
          id={integration.id}
          name={integration.name}
          description={integration.description}
          link={integration.link}
        />
      ))}
    </section>
  )
}

const Integration = ({ id, name, description, link }) => (
  <div className="p-6 md:p-8 flex flex-col bg-white border-border-weak-light border rounded-2xl md:shadow-solid">
    <div className="flex flex-row items-center space-x-2 mb-4">
      <IntegrationIcon id={id} className="w-8 h-8 flex-shrink-0" alt={name} />
      <h2 className="text-text-primary-light text-2xl font-extrabold tracking-tight leading-tight">
        {name}
      </h2>
    </div>
    <p className="my-4 text-text-secondary-light">{description}</p>
    <p className="mt-auto text-text-secondary-light">
      <Link
        className="text-text-primary-light active:bg-button-secondary-active-light hover:bg-button-secondary-hovered-light border border-border-normal-light inline-flex items-center p-4 pr-3.5 h-10 text-sm leading-tight bg-white rounded-lg space-x-2"
        to={link}
      >
        <span>All {name} features</span>
        <ArrowRight className="flex-shrink-0" size={20} weight="light" />
      </Link>
    </p>
  </div>
)
