import React from 'react'
import { BasicLayout } from '../layouts/basic'
import { Integrations } from '../components/Integrations'
import { SEOMeta } from '../components/SEOMeta'

const IntegrationsPage = ({ location }) => (
  <BasicLayout>
    <SEOMeta
      title="Integrations"
      description="Qualdesk integrates a realtime digital whiteboard with Jira, Linear, Trello and Google Sheets"
      path={location.pathname}
    />
    <section className="mx-auto px-6 md:pt-16 pb-16 md:pb-24">
      <div className="mx-auto max-w-6xl items-center flex flex-col">
        <h1 className="text-text-primary-light mt-6 text-3xl md:text-4xl font-extrabold tracking-tight leading-tight sm:mt-0 text-center">
          Whiteboard <br className="md:hidden" />+ your stack = 🚀
        </h1>
        <p className="text-text-primary-light mt-4 md:text-xl text-center max-w-2xl">
          Qualdesk brings the flexibility of a whiteboard to the tools you use
          every day. Work together live as a team, or take advantage of the
          freeform canvas for planning, thinking and creativity.
        </p>
      </div>
    </section>
    <Integrations />
  </BasicLayout>
)

export default IntegrationsPage
